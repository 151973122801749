import React from 'react';

function App() {
  return (
    <div className="app">
      <div className="content">
        <img src={require("./logo.svg")} />
        <div className="headings">
          <h1>Site coming soon</h1>
          <h2>We are currently in development.</h2>
        </div>
      </div>
    </div>
  );
}

export default App;
